import React, { Fragment } from 'react'
import {AiOutlineEye,AiOutlineRetweet} from 'react-icons/ai'
import {BiShareAlt} from 'react-icons/bi'
import { IconContext } from 'react-icons'
import {BsPlusLg} from 'react-icons/bs'
import Tv from './Tv'
import {useState,useRef,useEffect,useContext} from 'react'
import axios from 'axios'
import ProfileDetails from './ProfileDetails'
import UploadVideoImage from './UploadVideoImage'
import { nanoid } from 'nanoid'
import InfiniteScroll from "react-infinite-scroll-component";
import VoteHandler from './VoteHandler'
import {UserContext} from '../App'
import CommentModalController from './CommentModalController'
import TvVersionOne from './TvVersionOne'
import { Rings } from  'react-loader-spinner'
import { Link } from 'react-router-dom'
import SignInStatus from './account/SignInStatus'


function RecentPosts(){

    const[PostUploadModal,setPostUploadModal]=useState(false)
    const[posts,setPosts]=useState([])
    // const[loading,setLoading]=useState(true)
    const[page,setPage]=useState(1)
    const [hasMore, setHasMore] = useState(true);
    const[error,setError]=useState('')
    const[Country,setCountry]=useState('')
    const [showSignInStatus, setShowSignInStatus] = useState(false);

    const user=useContext(UserContext)
    const loadingRef = useRef(true);
    const[status,setStatus]=useState(true)


    const decodeCaptionText = (caption) => {
    
      const decodedCaption = decodeURIComponent(caption);
     
      return decodedCaption;
    };
    


// useEffect(() => {
//   // Check if country data exists in localStorage
//   const storedCountry = localStorage.getItem('country');

//   if (storedCountry) {
//     // If data exists, parse and set it
//     setCountry(JSON.parse(storedCountry));
    
//   } 
//   else{
//     setCountry("Global");
//   }
// }, []);

    //get all posts

    useEffect(()=>{

        const fetchData = async () => {
          
          try {
            // Retrieve 'country' from local storage
            const storedCountry = localStorage.getItem('country');
            
        
            // Set a default value if 'country' is not present
            const country = storedCountry 
        
            // Encode the 'country' value to ensure it's URL-safe
            const encodedCountry = encodeURIComponent(country);

            setCountry(encodedCountry)
        
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/v1/posts/recentPosts?postDetails={"page":${page},"country":${encodedCountry ? encodedCountry: "global"}}`
            );

              setPosts((prevPosts) => {

                const newPosts = response.data.filter((post) => !prevPosts.some((p) => p.post_id === post.post_id));
                return [...prevPosts, ...newPosts];
              })
              if (response.data.length < 5) {
                setHasMore(false);
              }
              setError('')
            } catch (error) {
              setPosts({})
              setError('Something went wrong')
            } finally {
              loadingRef.current = false
            }
          };

        
          fetchData();
        
    },[page])


    const fetchMoreData = () => {
        setPage(prevPage => prevPage + 1)
      };

      useEffect(() => {
        if (!user) {
          const signInStatusTimeout = setTimeout(() => {
            setShowSignInStatus(false);
          }, 120000); // 10 seconds
    
          return () => clearTimeout(signInStatusTimeout); // Clear the timeout if component unmounts
        }
      }, [user]);

      

    // contains the post structure
    return(

        
        <Fragment>
            
            <InfiniteScroll
        dataLength={posts.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={ 

<>
        <div className='post-loading' >
          <Rings
  height="100"
  width="100"
  color="#4fa94d"
  radius="6"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  ariaLabel="rings-loading"
/> 
</div>

<div id='posts-loading'>
    
    <p>Loading More Posts...</p>
    <div className="loading-spinner"></div>
</div>


</>

        }
      >
        {loadingRef.current ? 
        <div className='post-loading'>
          <Rings
  height="100"
  width="100"
  color="#4fa94d"
  radius="6"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  ariaLabel="rings-loading"
/> 
</div>
:
   posts.length>0?     
      posts.map(post=>(
            
        <div  className="post-parent" key={nanoid()}>
           
{/* contains profile contents that is the photo ,follow button and kebab menu */}

<ProfileDetails profile_picture_link={post.profile_picture_link} userName={post.username} date_time={post.date_time} post_id={post.post_id} email={post.email}/>

{/* contains the tv design structure. Post media will be displayed here */}
        {/* caption div */}
        
       <Tv file_link={post.file_link} file_type={post.file_type} post_id={post.post_id} page={page} numberOfVotes={post.numberOfVotes} numberOfComments={post.numberOfComments} Country={Country} views={post.view_count} uViews={post.distinctUserCount}/>

        {/* <TvVersionOne file_link={post.file_link} file_type={post.file_type} /> */}

{/* contains the like, view , comment , expose and share menu */}

        {/* <div className="post-reactions">
        <IconContext.Provider value={{size:"1.5em", color:"#FFFDD0" , padding:".5em"}} >
            <div className="view"><AiOutlineEye color="white"/><div className="numbers">10.7K</div> </div>

          <CommentModalController post_id={post.post_id} />
            

            <div className="expose"><AiOutlineRetweet /><div className="numbers">5000</div> </div>

            <VoteHandler email={post.email} post_id={post.post_id} page={page} numberOfVotes={post.numberOfVotes} />


            <div className="share"><BiShareAlt /></div>

            </IconContext.Provider >
        </div> */}
        

       {post.caption!=="1"?<div className="caption"><p>{decodeCaptionText(post.caption)}</p></div> :""}

      
       
        </div>
       

 ))
:

<div className='link-to-global'>
<h3>No Posts From {decodeURIComponent(Country)}.</h3>
<h3>Be the First One to Upload, or Click the Link Below to View Worldwide Hot Memes</h3>
<Link to="/global" id="link-to-global">
  View Global Posts
</Link>
</div>
} 
</InfiniteScroll>

        


{/* <div className="post-upload" onClick={()=>{setPostUploadModal(true)}}><BsPlusLg color="#FFF" size="1.5em" /></div>

{PostUploadModal && <UploadVideoImage closePostUploadModal={setPostUploadModal}/>} */}



{
  showSignInStatus && <SignInStatus />
} 

</Fragment>
        
    )

}

export default RecentPosts