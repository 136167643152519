import React,{useState,useContext} from 'react'
import { UserContext } from '../App';
import './app_styling.css'
// import './oldTvDesignCss.css'
import {IconContext} from "react-icons"
import {FaHome,FaVideo} from "react-icons/fa"
import {GiPodiumWinner} from "react-icons/gi"
import {MdOutlineAccountCircle} from "react-icons/md"
import { Link,useMatch,useResolvedPath } from 'react-router-dom'
import {BsPlusLg} from "react-icons/bs"
import {BsCashCoin} from "react-icons/bs"
import UploadVideoImage from './UploadVideoImage'

function MainNavigationMenu() {

    const user=useContext(UserContext)

    const[PostUploadModal,setPostUploadModal]=useState(false)
    return (
        //main bottom fixed navigation
        <IconContext.Provider value={{size:"1.1em", color:"#FFFDD0"}} >

            {/* //nav set to flex raw , ComponentLinks wrapped into divs for flex column */}
<>
        <nav className="main-navigation-menu">

            
            <ComponentLinks to="/"><div className="links"> <div> <FaHome /> </div> <div>Home</div>  </div></ComponentLinks>
           
            <ComponentLinks to="/wallOfFame"><div className="links"> <div><GiPodiumWinner /> </div> <div>WallOfFame</div> </div></ComponentLinks>



        {user ?         
        <Link id='upload-link' onClick={()=>{setPostUploadModal(true)}}>
          <div className="links" id='post-link'> <div className='post-upload' > <BsPlusLg /></div> <div>Upload</div> </div>
          </Link> 
    :
    <Link to="/account" id='upload-link'>
    <div className="links" id='post-link'> <div className='post-upload'> <BsPlusLg /></div> <div>Upload</div> </div>
    </Link>
    }
 
 <ComponentLinks to="/memeBattle"><div className="links"> <div><BsCashCoin /> </div> <div>Earn</div> </div></ComponentLinks>
            
            <ComponentLinks to="/videosPage"><div className="links"> <div><FaVideo /> </div> <div>Funny</div>   </div></ComponentLinks>
          
            

            
            <ComponentLinks to="/account"><div className="links"> <div><MdOutlineAccountCircle /> </div> <div>Ac</div> </div> </ComponentLinks>

 
            
            
        </nav>
{PostUploadModal && <UploadVideoImage closePostUploadModal={setPostUploadModal}/>}

        </>
        </IconContext.Provider>
        
    )
    
}

export default MainNavigationMenu

function ComponentLinks({to,children,...props}){

    const resolvedPath=useResolvedPath(to)
    const isActive=useMatch({path: resolvedPath.pathname,end:true})
    return(
        
        <IconContext.Provider value={{ size: "1.1em", color: isActive ? "#00b41e" : "#FFFDD0" }}>
        <Link to={to} {...props} className={isActive ? 'main-active' : ""}>
          {children}
        </Link>
      </IconContext.Provider>
    )

}


